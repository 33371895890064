import React from 'react';

const CustomSelect = ({ className, ...props }) => {

  return (
    <select className="custom-text-input" {...props}>
      {props.children}
    </select>
  );
};

export default CustomSelect;
