function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}

export default function PersonInfo({ person }) {
  return (
    <div className="text-xs sm:text-sm text-right text-gray-600 pb-3">
      {person.full_name || person.date_of_birth
        ? <>{person.full_name} {person.date_of_birth && <span>| {formatDate(new Date(person.date_of_birth + 'T00:00'))}</span>}</>
        : <>&nbsp;</> 
      }
    </div>
  )
}
