import React from 'react';
import CustomButton from '../buttons/CustomButton'

const PaginationButtons = ({
  handlePreviousTab,
  handleNextTab,
  prevTabBtnText,
  nextTabBtnText
}) => {
  return (
    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
      {prevTabBtnText &&
        <button
          type="button"
          onClick={handlePreviousTab}
          className="text-sm font-semibold leading-6 text-gray-900 hover:border-fabric-light-blue">
          {prevTabBtnText}
        </button>
      }
      {nextTabBtnText &&
        <CustomButton
          type="button"
          onClick={handleNextTab}>
          {nextTabBtnText}
        </CustomButton>
      }
    </div>
  );
}

export default PaginationButtons;
