import React from 'react';

const CustomButton = ({ children, className, ...props }) => {
  // Define the default button classes
  const defaultClasses = "bg-fabric-light-blue enabled:hover:bg-fabric-light-blue-range-600 text-white text-slate-100 font-semibold py-2 px-4 rounded disabled:opacity-65";

  // Combine default classes with custom classes if provided
  const combinedClasses = className ? `${defaultClasses} ${className}` : defaultClasses;

  return (
    <button
      className={combinedClasses}
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;
