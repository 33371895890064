export function getAddressFromGooglePlace(place) {
    const address = {};
    if (place.geometry && place.geometry.location) {
        address.formatted_address = place.formatted_address;
        const addressComponents = place.address_components;
        // Loop through the address components and extract the relevant information
        addressComponents.forEach(component => {
            switch (component.types[0]) {
                case 'street_number':
                    address.houseNum = component.short_name;
                    break;
                case 'postal_code':
                    address.zip = component.long_name;
                    break;
                case 'route':
                    address.streetName = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    address.state = component.short_name;
                    break;
                case 'locality':
                    address.city = component.long_name;
                    break;
                default:
                    break;
            }
        });
    }
    return address;
}
