import Tooltip from "../tooltips/Tooltip";

const CustomLabel = ({ children, tooltip, ...props }) => {
  return (
    <div className='flex space-between'>
      <label
        {...props}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {children}
      </label>
      {
        tooltip &&
        <Tooltip title={children}>
          {tooltip}
        </Tooltip>
      }
    </div>
  );
};

export default CustomLabel;
