// src/utils/api.js

export async function apiRequest(method, endpoint, formSessionId, csrfToken, data = null, extraParams = null, handleSuccess, handleError) {
    let url = `${process.env.REACT_APP_FABRIC_BASE_URL}/${endpoint}`;

    const options = {
        method,
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    };

    if (formSessionId) {
        options.headers['X-Form-Session-Id'] = formSessionId;
    }

    if (csrfToken) {
        options.headers['X-CSRFToken'] = csrfToken;
    }

    if (data) {
        options.body = JSON.stringify(data);
    }

    if (extraParams) {
        const urlObj = new URL(url);
        Object.keys(extraParams).forEach(key => urlObj.searchParams.append(key, extraParams[key]));
        url = urlObj.toString();
    };

    try {
        const response = await fetch(url, options);

        if (!response.ok && response.status !== 422) {
            return await handleError({has_error:true, messages: [ 'We unexpectedly ran into an issue. We will be looking into this shortly!' ]}, response.status);
        }
        if (response.status === 422) {
            const errorData = await response.json();
            return await handleError(errorData, response.status);
        }

        if (response.ok) {
            const successData = await response.json();
            return await handleSuccess(successData);
        }


    } catch (error) {
        return await handleError({has_error:true, messages: [ 'We unexpectedly ran into an issue. We will be looking into this shortly!' ]});
    }
}

export async function getSession(handleSuccess, handleError) {
    return await apiRequest('GET', 'session/', null, null, null, null, handleSuccess, handleError);
}

export async function getIsLoginRequiredForFormGroup(formGroupId, csrfToken, handleSuccess, handleError) {
    return await apiRequest('GET', `api/anon-form-engine-login-required?formGroupId=${formGroupId}`, null, csrfToken, null, null, handleSuccess, handleError);
}

export async function getIsLoginRequiredForFormSession(formSessionId, csrfToken, handleSuccess, handleError) {
    return await apiRequest('GET', `api/anon-form-engine-login-required?formSessionId=${formSessionId}`, null, csrfToken, null, null, handleSuccess, handleError);
}

export async function getFormTemplate(id, formSessionId, csrfToken, handleSuccess, handleError) {
    if (formSessionId) {
        return await apiRequest('GET', `api/form-templates/${id}`, formSessionId, csrfToken, null, null, handleSuccess, handleError);
    }

    // unauthenticated - no form session
    return await apiRequest('GET', `api/anon-form-templates/${id}`, null, csrfToken, null, null, handleSuccess, handleError);
}

export async function getFormTemplateGroup(formGroupId, csrfToken, handleSuccess, handleError) {
    return await apiRequest('GET', `api/anon-form-template-groups/${formGroupId}`, null, csrfToken, null, null, handleSuccess, handleError);
}

export async function getFormSession(formSessionId, extraParams, csrfToken, handleSuccess, handleError) {
    return await apiRequest('GET', `api/form-sessions/`, formSessionId, csrfToken, null, extraParams, handleSuccess, handleError);
}

export async function postFormSubmission(data, formSessionId, csrfToken, handleSuccess, handleError) {
    if (formSessionId) {
        return await apiRequest('POST', 'api/form-submissions/', formSessionId, csrfToken, data, null, handleSuccess, handleError);
    }

    // unauthenticated - no form session. This is probably the demographics (patient creation) form.
    return await apiRequest('POST', 'api/anon-form-submissions/', formSessionId, csrfToken, data, null, handleSuccess, handleError);
}

export async function putFormSubmission(id, data, formSessionId, csrfToken, handleSuccess, handleError) {
    return await apiRequest('PUT', `api/form-submissions/${id}`, formSessionId, csrfToken, data, null, handleSuccess, handleError);
}

export async function postSendVerificationCode(formSessionId, csrfToken, handleSuccess, handleError) {
    return await apiRequest('POST', 'api/verify-phone/', formSessionId, csrfToken, null, null, handleSuccess, handleError);
}