import { XCircleIcon } from '@heroicons/react/20/solid'

export default function InputErrors({ errorMessages }) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon aria-hidden="true" />
        </div>
        <div className="ml-3">
          <div className="text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              {
                errorMessages.map(mess => (<li>{mess}</li>))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}