import React from 'react';
import Input from 'react-phone-number-input/input';

const CustomPhoneInput = ({ ...props }) => {
  const { id, name, value, required, onChange, disabled, placeholder } = props

  return (
    <div>
        <Input
            country='US'
            name={name}
            id={id}
            required={required}
            className="custom-text-input"
            value={value}
            onChange={(v) => onChange(v) } 
            disabled={disabled}
            placeholder={placeholder} />
    </div>
  );
};

export default CustomPhoneInput;

