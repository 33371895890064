import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import ProgressBar from '../navigation/ProgressBar';
import { getFormTemplateGroup, getFormSession, getIsLoginRequiredForFormGroup, getIsLoginRequiredForFormSession } from '../../utils/api';
import { UserLoginState } from '../../utils/constants';
import { FabricForm, VerifyPhone, HttpError } from '.';
import PersonInfo from '../person/PersonInfo';
import { CustomMarkdownWidget } from '../inputs/CustomRjsfInputs';


const FormSet = ({ setLoading, csrfToken, setIsLoginRequired, userLoginState }) => {
  const { formSessionId, formTemplateGroupId, templateId } = useParams();  // URL route params  
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const verifyPhoneTemplateId = process.env.REACT_APP_VERIFY_PHONE_FORM_TEMPLATE_ID;
  const defaultFormTemplateGroupId = process.env.REACT_APP_DEFAULT_FORM_GROUP_ID;

  const [formLoadingError, setFormLoadingError] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [registrationFormTemplates, setRegistrationFormTemplates] = useState([]);
  const [registrationForms, setRegistrationForms] = useState([]);
  const [currentFormSessionId, setCurrentFormSessionId] = useState(formSessionId); // keep as a state variable because it could change without a page reload (i.e. session creation)

  const [isReadOnly, setIsReadOnly] = useState(false);
  const asModalView = searchParams.get('mo') === '1';
  const showDemographicsHeader = searchParams.get('sd') !== '0';
  const isNavigateToTabAction = useRef(false);

  // Define Person object to store relevant data
  const [person, setPerson] = useState({
    id: null,
    full_name: '',
    date_of_birth: '',
  });

  const activeRegistrationForm = registrationForms.length > 0 ? registrationForms[activeTab] : null;
  const activeRegistrationFormTemplate = registrationFormTemplates.length > 0 ? registrationFormTemplates[activeTab] : null;

  useEffect(() => {
      if (userLoginState === UserLoginState.UNKNOWN) {
        // don't start loading data until we've checked our login state. This helps avoid visually annoying re-renders.
        return;
      }
      initFormSet();
  }, [userLoginState])

  // Keep the URL in sync w/ the current session and template ID
  useEffect(() => {
    if (!currentFormSessionId) {
      return;
    }

    const activeRegistrationFormTemplate = registrationFormTemplates.length > 0 ? registrationFormTemplates[activeTab] : null;
    if (!activeRegistrationFormTemplate) {
      return;
    }

    // only update the URL if the template (page) is changing
    const pathParts = location.pathname.split('/');
    if (pathParts.length === 4 && pathParts[3] === activeRegistrationFormTemplate.id) {
      return;
    }

    // only update the URL based on user action (navigateToTab). Otherwise, the browser will handle updating the URL (e.g. back/forward buttons)
    if (isNavigateToTabAction.current || !templateId) {
      let newUrl = `/s/${currentFormSessionId}/${activeRegistrationFormTemplate.id}?`;
      if (isReadOnly) {
        newUrl += 'm=ro'; // translates to "mode = readonly"
      }
      if(asModalView){
        newUrl += '&mo=1';
      }
      if(!showDemographicsHeader){
        newUrl += '&sd=0';
      }
      navigate(newUrl);
    }

  }, [activeTab, registrationFormTemplates, currentFormSessionId, navigate, location.pathname, templateId, isReadOnly, asModalView, showDemographicsHeader])

  // Keep the state in sync with the URL template ID
  useEffect(() => {
    // update the state, but only if this is NOT a user action (navigateToTab). I.e. we only want to update the state if the browser updated the URL (back/forward buttons)
    if (!isNavigateToTabAction.current) {
      const formIndex = registrationFormTemplates.findIndex(template => template.id === templateId);
      if (formIndex > -1 && formIndex !== activeTab) {
        setActiveTab(formIndex);
      }
    }
    isNavigateToTabAction.current = false;
  }, [templateId, activeTab, registrationFormTemplates])


   // If we're in a modal (iframe), keep the parent updated with our current height
   useEffect(() => {
    if (!asModalView) {
      return;
    }
    // Node you want to observe for mutations
    const targetNode = document.getElementById('formContent')

    // the observer's callback should send a message to the parent with our current height
    const observer = new MutationObserver((mutations) => {
      window.parent.postMessage({ 'height': document.getElementById('root').offsetHeight }, process.env.REACT_APP_FABRIC_BASE_URL);
    });

    // start observing the target node for configured mutations
    if (targetNode) {
      observer.observe(targetNode, {
        // options for the observer (which mutations to observe)
        attributes: false,
        childList: true,
        subtree: true
      });
    }
    // disconnect observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);  // Empty dependency array means this effect will only run once after the initial rendering


  const initFormSet = () => {
    if (currentFormSessionId) {
      getIsLoginRequiredForFormSession(currentFormSessionId, csrfToken, initData, (data) => {console.error(data)});
    }
    else if (formTemplateGroupId || defaultFormTemplateGroupId) {
      getIsLoginRequiredForFormGroup(formTemplateGroupId || defaultFormTemplateGroupId, csrfToken, initData, (data) => {console.error(data)});
    }
  }

  const initData = (isLoginRequiredResponse) => {
    setIsLoginRequired(isLoginRequiredResponse);
    if (isLoginRequiredResponse === true && userLoginState !== UserLoginState.LOGGED_IN) {
      // login is required, and we're not logged in. Another hook is going to redirect to the login page, so don't bother trying to load data
      return;
    }

    if (currentFormSessionId) {
      // Query param 'm' == 'ro' translates to 'mode' == 'readonly'
      const requestReadOnly = searchParams.get('m') === 'ro';
      getFormSession(currentFormSessionId, {'requestReadOnly' : requestReadOnly}, csrfToken, handleFormSessionLoad, handleFormLoadError);
    }
    else if (formTemplateGroupId || defaultFormTemplateGroupId) {
      getFormTemplateGroup(formTemplateGroupId || defaultFormTemplateGroupId, csrfToken, handleFormGroupLoad, handleFormLoadError)
    }
    else {
      console.error('No form group or session available');
      setLoading(false);
    }
  }

  const handleFormGroupLoad = (data) => {
    setRegistrationFormTemplates(data);

    // set up the intially-blank form based on each template
    const regForms = data.map(template => {
      return {
        templateId: template.id,
        form: {},
        id: null,
      }
    });
    setRegistrationForms(regForms);
    setLoading(false);
  }

  const handleFormSessionLoad = (data) => {
    if (data.isReadOnly || searchParams.get('m') === 'ro') { 
      // Query param 'm' == 'ro' translates to 'mode' == 'readonly'
      // We want to render readonly if a query param tells us to, or if the backend tells us the session should be readonly
      setIsReadOnly(true);
    }
    setRegistrationFormTemplates(data.templates);

    // if we have a person object, fill that out
    if (data.person && data.person.id) {
      setPerson(data.person)
    }

    // re-hydrate the forms that were previously filled out
    const regForms = data.templates.map(template => {
      // if we have a filled-out form for this template, hydrate that
      const filledForm = data.forms.find(form => form.form_template === template.id);
      if (filledForm) {
        return {
          templateId: template.id,
          form: filledForm.form,
          id: filledForm.id
        };
      }

      // otherwise return a blank form
      return {
        templateId: template.id,
        form: {},
        id: null,
      }
    });
    setRegistrationForms(regForms);

    // if a template ID was included in the URL, set our state appropriately
    if (templateId) {
      const formIndex = regForms.findIndex(regForm => regForm.templateId === templateId);
      if (formIndex > -1) {
        setActiveTab(formIndex);
      }
    }
    setLoading(false);
  }

  const handleFormLoadError = (errorData, httpStatus) => {
    setFormLoadingError({
      httpStatus: httpStatus,
    });

    console.error('Error loading forms', errorData);
    setLoading(false);
  }

  const updateRegistrationForms = (updatedRegistrationForm, index) => {
    // Create a copy of the registrationForms array
    const updatedRegistrationForms = [...registrationForms]
    // Update the form data of the specific registrationForm object
    updatedRegistrationForms[index] = updatedRegistrationForm;
    // Set the state with the updated array
    setRegistrationForms(updatedRegistrationForms);
  
    if (activeRegistrationFormTemplate.can_create_person) {
      // These are the minimal set of person fields that we need to keep special track of in order
      // to include the person link for every submitted form or to render the PersonInfo component
      setPerson({
        id: updatedRegistrationForm.form['id'],
        full_name: updatedRegistrationForm.form['full_name'],
        date_of_birth: updatedRegistrationForm.form['date_of_birth'],
      })
    }
  }; 

  const navigateToTab = (tabIndex) => {
    setActiveTab(tabIndex);
    isNavigateToTabAction.current = true;
  };

  const handleNextClick = () => {
    navigateToTab(activeTab + 1);
  };

  const handlePrevClick = () => {
    navigateToTab(activeTab - 1);
  };

  const getPrevTabTitle = () => {
    if (activeTab === 0) {
      return null;
    }
    return "Go Back";
  }

  const getNextTabTitle = () => {
    const isLastTab = activeTab === registrationFormTemplates.length - 1;
    const isLoggedIn = userLoginState === UserLoginState.LOGGED_IN;
    const isSaveableForm = registrationFormTemplates[activeTab].has_form_data;

    if (isReadOnly) {
      if (isLastTab) return null;
      return 'Continue';
    }

    if (isLastTab) {
      if (isLoggedIn) return "Save & Finalize";
      return null; // if no logged in user and this is the last tab, don't show a next button
    }

    if (isSaveableForm) {
      return "Save & Continue";
    }
    return "Continue";
  }

  const getProgressSteps = () => {
    if (registrationForms.length === 0) {
      return [];
    }

    return registrationFormTemplates.map((template, index) => {
      return {
        id: index + 1,
        name: template.name,
        status: activeTab === index ? 'current' : //current tab
          registrationForms[index].id ? 'complete' : //submitted form 
            currentFormSessionId && template.can_create_person ? 'complete' : //person has been posted and is (hardcoded) demographic form 
              !template.has_form_data && activeTab > index ? 'complete' : //welcome forms will not be posted (just check if it is previous tab)
                activeTab < index ? 'upcoming' : '',
        disabled: (!currentFormSessionId && !template.can_create_person && template.has_form_data)
      }
    });
  }

  if (formLoadingError) {
    return <HttpError httpStatus={formLoadingError.httpStatus} />
  }
  return (
    <>
      {userLoginState === UserLoginState.LOGGED_IN && showDemographicsHeader && <PersonInfo person={person} />}
      {userLoginState === UserLoginState.LOGGED_IN && registrationFormTemplates.length > 1 && <ProgressBar steps={getProgressSteps()} navigateToTab={navigateToTab} />}
      <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
        <div>
          <div className="space-y-10 divide-y divide-gray-900/10">
            <div id="formContent" className={`grid grid-cols-1 gap-x-8 ${registrationFormTemplates.length > 1 ? 'pt-10' : ''} `}>
              {activeRegistrationFormTemplate &&
                <div>
                  {showDemographicsHeader && 
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      {activeRegistrationFormTemplate.name}
                    </h2>
                  }

                  <div className="mt-2 mb-3 text-sm leading-6 text-gray-600">
                    {activeRegistrationFormTemplate.description &&
                        <CustomMarkdownWidget value={activeRegistrationFormTemplate.description} />
                    }
                  </div>
                  
                </div>
              }
              <div className='md:col-span-2 '>                  
                  { // "Hard coding" Verify Phone, still represented as form template record in database
                    activeRegistrationForm &&
                    activeRegistrationForm.templateId === verifyPhoneTemplateId &&
                    <VerifyPhone
                      index={activeTab}
                      isLastForm={activeTab === registrationFormTemplates.length - 1}
                      formSessionId={currentFormSessionId}
                      registrationForm={activeRegistrationForm}
                      updateRegistrationForms={updateRegistrationForms}
                      csrfToken={csrfToken}
                      setLoading={setLoading}
                      person={person}
                      verifyPhoneTemplateId={verifyPhoneTemplateId}
                      nextTabTitle={getNextTabTitle()}
                      goToNextTab={handleNextClick}
                      prevTabTitle={getPrevTabTitle()}
                      goToPrevTab={handlePrevClick}
                      isReadOnly={isReadOnly}
                      asModalView={asModalView} />}

                  {activeRegistrationForm &&
                    activeRegistrationForm.templateId !== verifyPhoneTemplateId &&              
                    <FabricForm
                      index={activeTab}
                      isLastForm={activeTab === registrationFormTemplates.length - 1}
                      csrfToken={csrfToken}
                      canCreatePerson={activeRegistrationFormTemplate.can_create_person}
                      person={person}
                      setLoading={setLoading}
                      templateId={activeRegistrationFormTemplate.id}
                      formTemplateGroupId={formTemplateGroupId || defaultFormTemplateGroupId}
                      nextTabTitle={getNextTabTitle()}
                      goToNextTab={handleNextClick}
                      prevTabTitle={getPrevTabTitle()}
                      goToPrevTab={handlePrevClick}
                      formSessionId={currentFormSessionId}
                      setCurrentFormSessionId={setCurrentFormSessionId}
                      registrationForm={activeRegistrationForm}
                      updateRegistrationForms={updateRegistrationForms}
                      isReadOnly={isReadOnly}
                      asModalView={asModalView}
                      userLoginState={userLoginState} />}

              </div>
            
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormSet;