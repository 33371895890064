import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoadScript } from '@react-google-maps/api';

import './output.css';

import { FormSet } from './components/views';
import LoadingScreen from './components/LoadingScreen';
import ApplicationShell from './components/ApplicationShell';
import { getSession } from './utils/api';
import { UserLoginState } from './utils/constants';


function App() {

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const googleMapsLibraries = useMemo(() => ['places'], []);
  const loadingElement = <LoadingScreen />;

  const [loading, setLoading] = useState(true);

  const [csrfToken, setCsrfToken] = useState(false);
  const [userSession, setUserSession] = useState({
    state: UserLoginState.UNKNOWN, // Needs to be tri-state; we'll use this to avoid redirecting to the login flow prematurely
    user: ""
  }); 
  const [isLoginRequired, setIsLoginRequired] = useState(false);  

  useEffect(() => {
    const handleGetSessionSuccess = async (data) => {
      await getCSRF();
      if (data.user) {
        setUserSession({
          user: data.user,
          state: UserLoginState.LOGGED_IN,
        });
      }
      else {
        setUserSession({
          user: "",
          state: UserLoginState.NOT_LOGGED_IN,
        });
      }
    }
    getSession(handleGetSessionSuccess, () => { })
  }, [])

  // If login is needed (based on the form template group) and a user isn't logged in, redirect to the login flow
  useEffect(() => {
    if (isLoginRequired && userSession.state === UserLoginState.NOT_LOGGED_IN) {
      window.location.replace(`${process.env.REACT_APP_FABRIC_BASE_URL}/login?redirect=${window.location.href}`);
    }

  }, [userSession, isLoginRequired]);

  const getCSRF = async () => {
    const res = await fetch(process.env.REACT_APP_FABRIC_BASE_URL + "/csrf/", { credentials: "include" });
    const csrfToken = res.headers.get("X-CSRFToken");
    setCsrfToken(csrfToken);
  }

  const commonFormSetProps = {
    csrfToken: csrfToken,
    setLoading: setLoading,
    setIsLoginRequired: setIsLoginRequired,
    userLoginState: userSession.state,
  }
  
  return (
    <>
      {
        <LoadScript
          loadingElement={loadingElement}
          googleMapsApiKey={googleMapsApiKey}
          libraries={googleMapsLibraries}
        />
      }
      {
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<ApplicationShell user={userSession.user} isLoginRequired={isLoginRequired} />}>
              <Route index element={<FormSet {...commonFormSetProps} />} />
              <Route path="g/:formTemplateGroupId" element={<FormSet {...commonFormSetProps} />} />
              <Route path="s/:formSessionId" element={<FormSet {...commonFormSetProps} />}>
                <Route path=":templateId" element={<FormSet {...commonFormSetProps} />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      }
      {
        <div style={{ display: loading ? "block" : "none" }}>
          <LoadingScreen />
        </div>
      }
    </>
  );
}

export default App;
