import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { CustomMarkdownWidget } from '../inputs/CustomRjsfInputs';

export default function Alert({ messages, children, type }) {
  const bgClass = type === "success" ? "bg-green-50"
    : type === "error" ? "bg-red-50"
      : "";
  const textClassBold = type === "success" ? "text-green-800"
    : type === "error" ? " text-red-800"
      : "";
  const textClassNormal = type === "success" ? "text-green-700"
    : type === "error" ? " text-red-700"
      : "";
  return (
    <div className={`${bgClass} rounded-md p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {
            type === "success" &&
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          }
          {
            type === "error" &&
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          }
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textClassBold}`}>
            {
              children
            }
            {
              !children && type === "error" &&
              <span> Oops! There was a problem with your request</span>
            }
          </h3>
          {
            messages &&
            <div className={`mt-2 text-sm ${textClassNormal}`}>
              <ul className="list-disc space-y-1 pl-5">
                {messages.map(message =>
                (
                  <li>
                    <CustomMarkdownWidget value={message} linksInSameTab={true} />
                  </li>
                ))}
              </ul>
          </div>
          }
        </div>
      </div>
    </div>
  )
}
