import React from 'react';

const CustomInput = (props) => {
  let className = 'custom-text-input';
  if (props.className) {
    className = className + ' ' + props.className;
  }
  return (
    <input
      {...props}
      className={className}
    />
  );
};

export default CustomInput;