import React from 'react';
import './LoadingScreen.css'; // Assuming you have a CSS file for styling

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-content">
        <img src="/pug-washing-machine.gif" alt="Loading..." />
      </div>
    </div>
  );
}

export default LoadingScreen;