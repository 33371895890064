import { Popover } from "flowbite-react";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Markdown from 'react-markdown'


export default function Tooltip({ title, children }) {
  const content = (
    <div className="w-100 max-w-xs text-sm text-gray-500 bg-white">
      <div className="px-3 py-2 bg-white border-none custom-markdown">
        <Markdown>{children}</Markdown>
      </div>
    </div>
  );

  return (
    <Popover content={content}
      theme={{
        "base": "absolute z-20 inline-block w-max  bg-white outline-none border  rounded-lg shadow-sm ",
        "content": "z-10 overflow-hidden rounded-[7px]",
        "arrow": {
          "base": "absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border ",
          "placement": "-4px"
        }
      }}
      placement="top"
      arrow={false}>
      <InformationCircleIcon className="ml-1 mt-1 min-w-4 w-4 text-fabric-light-blue cursor-pointer" />
    </Popover>
  )
}